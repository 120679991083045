 // Enable Carousel Indicators
$(".news-item1").click(function(){
    $("#carousel1").carousel(0);
  });
$(".news-item2").click(function(){
    $("#carousel1").carousel(1);
  });
$(".news-item3").click(function(){
    $("#carousel1").carousel(2);
  });

// Enable Carousel Controls
$(".news-left").click(function(){
    $("#carousel1").carousel("prev");
});
$(".news-right").click(function(){
    $("#carousel1").carousel("next");
});